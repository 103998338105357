import React from 'react';
import PrivateComponent from '@shared/components/private-component';
import LoginComponent from '@shared/components/login';
import Layout from '@org/components/layout';
import Seo from '@org/components/seo';

const Login = () => {
  return (
    <Layout>
      <Seo title="Login" />
      <PrivateComponent>
        <LoginComponent />
      </PrivateComponent>
    </Layout>
  );
};

export default Login;
